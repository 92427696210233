@font-face { font-family: Lora; 
    src: url('../fonts/Lora-VariableFont_wght.ttf'); }

p,
h1,
h2,
h3,
h4,
li {
  font-family: 'Lora';
  font-size: 20px;
}

.display-none{
  display:none ;
}

.cafe-info {
  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  padding: 15px 32px;
  font-family: 'Lora';
  font-size: 22px;

  text-align: center;
  display: inline-block;
}

.btn-cafe-black {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: 2px solid rgb(0, 0, 0);
  padding: 15px 32px;
  font-family: 'Lora';
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  &.btn-sm {
    padding: 8px 18px;
    font-size: 18px;
  }
}

.btn-cafe-black:hover {
  cursor: pointer;
  color: rgb(0, 0, 0);
  background-color: rgb(248, 242, 240);
  border: 2px solid rgb(133, 85, 88);
}

#header {
  top: -80px;
  height: 80px;
  background: rgba(255, 255, 255, 0);
  transition: 0.5s;
}

.navbar-toggler {
  display: none !important;
}

#home-imprint{
  width: 100%;
  height: 50vh;
  overflow: hidden;
  padding: 0;
  .home-item {
    width: 100%;
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/assets/img/background.webp);
    background-blend-mode: darken;
  }
  .home-container {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .home-content {
    text-align: center;
    color: #fff;
  }
}

#home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 0;

  .bg {
    width: 100%;
    object-fit: cover;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    &.img0{
      background-image: url(/assets/img/background.webp);
    }
    &.img1{
      background-image: url(/assets/img/background2.webp);
    }
    &.img2{
      background-image: url(/assets/img/background3.webp);
    }
  }

  // .home-item::before {
  //   content: "";
  //   background-color: rgba(0, 0, 0, 0.5);
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   bottom: 0;
  // }

  .home-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .home-content {
    text-align: center;
    color: #fff;
  }
}

.navbar-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.end {
  width: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#header.header-transparent {
  background: transparent;
}

.header-scrolled {
  top: 0px !important;
  background: rgba(255, 255, 255, 1) !important;
  transition: 0.5s;
}

.c-red {
  color: rgb(144, 77, 81);
}

.bg-red {
  background-color: rgb(194, 143, 129)
}

.padding {
  padding: 40px;
}

#cafe {
  padding: 60px;
}

.about {
  background: rgb(248, 242, 240);
}

.bg-gallery {
  background: rgb(112, 110, 110);
}

.about .box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 65%;
  min-height: 380px;
  max-height: 380px;
  max-width: 700px;
  position: relative;
}

.about .content {
  padding: 0px 0px 0px 60px;
}


.map-frame {
  overflow: hidden;
  height: 480px;
  width: 100%
}

.gallery {
  padding-bottom: 0;
}

.gallery .gallery-item {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  position: relative;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.gallery .gallery-item img {
  transition: all 0.3s;
}

.gallery .gallery-item:hover img {
  transform: scale(1.05);
}


.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}


.scrolled {
  top: 0px !important;
  transition: 0.5s;
  padding-top: 5px;
  max-height: 75px;
}


.navbar a,
.navbar a:focus {
  font-family: 'Lora';
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px 34px;
  font-size: 22px;
  font-weight: 450;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  transition: 0.4s;
  cursor: pointer !important;
}

.navbar .invert {
  color: rgb(0, 0, 0);
  transition: 0.4s;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: rgb(133, 85, 88);
}

.navbar-logo {
  top: -80px;
  transition: 0.5s;
  padding-top: 5px;
  min-height: 75px;
  max-height: 75px;
}
.navbar-logo:hover{
  cursor: pointer !important;
}

.fa-1x {
  font-size: 1.5rem;
}




@media (max-width: 992px) {

    #header {
    height: 60px; 
  }

  .navbar-logo {
    min-height: 55px;
    max-height: 55px;
  }

  .navbar a,
  .navbar a:focus {
    padding: 10px 0 10px 24px !important;
    font-size: 20px !important;
  }

  #cafe {
    padding: 40px;
  }

  .about .content {
    padding: 0px 40px 0px 40px;
  }

  .about .box {
    min-height: 280px;
  }

}
@media (max-width: 768px) {

  .navbar-toggler {
    display: flex !important;
    min-height: 70px !important;
    border: none !important;
    color: rgba(255, 255, 255, 0) !important;
  }
  
  #cafe {
    padding: 40px;
  }

  .about .content {
    padding: 30px 20px 0px 0px;
  }

  .map-frame {
    height: 300px ;
  }
}

.fade-in {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.fade-in-top {
  -webkit-animation: fade-in-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation: fade-in-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
0% {
  opacity: 0.2;
}
100% {
  opacity: 1;
}
}
@keyframes fade-in {
0% {
  opacity: 0.2;
}
100% {
  opacity: 1;
}
}

@-webkit-keyframes fade-in-top {
0% {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  opacity: 0;
}
100% {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}
}
@keyframes fade-in-top {
0% {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  opacity: 0;
}
100% {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
}